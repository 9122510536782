export const DemoBlock = ({
  title,
  children,
  desc,
}: {
  title: any;
  children: any;
  desc?: any;
}) => (
  <div className="p-[12px] ">
    <h3>{title}</h3>
    {children}
    {desc && <p>{desc}</p>}
  </div>
);
