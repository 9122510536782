import { NavBar, TabBar } from "antd-mobile";
import { AppOutline, MessageOutline, UserOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react";
import CreateMess from "./CreateMess";
import Queues from "./Queues";

const tabs = [
  {
    key: "/",
    title: "Tạo thông báo",
    icon: <AppOutline />,
  },
  {
    key: "/message",
    title: "Messages",
    icon: <MessageOutline />,
  },
  {
    key: "/me",
    title: "Me",
    icon: <UserOutline />,
  },
];

export default function App() {
  const [page, setPage] = useState(tabs[1]);

  useEffect(() => {
    const params = new URLSearchParams(window?.location?.search);
    const token = params.get("tokenJwt");

    if (token) {
      localStorage.setItem("tokenJwt", token);
    } else {
      window.location.replace("https://github.com");
    }
  }, []);

  return (
    <main>
      <NavBar back={""} backArrow={""}>
        {page.title}
      </NavBar>

      <div>
        {(() => {
          if (page.key === "/message") {
            return <Queues />;
          }
          return <CreateMess />;
        })()}
      </div>

      <div className="fixed w-full bottom-0 left-0 bg-white">
        <TabBar
          activeKey={page.key}
          onChange={(value) => {
            console.log({ value });
            const t = tabs.find((k) => k.key === value);
            setPage(t || tabs[0]);
          }}
        >
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </main>
  );
}
