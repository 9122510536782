import {
  Button,
  Divider,
  ImageUploader,
  Modal,
  TextArea,
  Toast,
} from "antd-mobile";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "./config";
import { DemoBlock } from "./DemoBlock";
import PickUsers from "./PickUsers";

export default function CreateMess() {
  const [telegramId, setTelegramId] = useState("");

  const [m, setM] = useState(``); // Text attributes _italic_, **bold**, .\nA [link](http://example.com).
  const [btns, setBtns] = useState(``);

  const [userType, setUserType] = useState("all");
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());

  // const [buts, setButs] = useState([
  //   "earn_xaao",
  //   "play_game",
  //   "join_community",
  //   "how_play",
  // ]);

  const [fileList, setFileList] = useState<any[]>([
    // {
    //   fileid:
    //     "AgACAgUAAxkDAAIGBGZ9LuG1746F6_e6-labu63Ol3qUAAJuwDEbuFfpV0zVAeWfKG0QAQADAgADeAADNQQ",
    //   url: "http://183.81.32.36:3005/1719480031720_Screenshot 2023-11-24 135933.png",
    // },
  ]);
  useEffect(() => {
    const id = new URLSearchParams(window?.location?.search).get("id");
    if (id) {
      setTelegramId(id);
    }
  }, []);

  return (
    <div>
      <DemoBlock title={`Hình ảnh`} desc={`Nhấp để tải ảnh lên`}>
        <ImageUploader
          maxCount={1}
          multiple={false}
          value={fileList}
          onChange={setFileList}
          upload={async (file) => {
            const formData = new FormData();
            formData.append("file", file, file.name);

            const requestOptions: any = {
              method: "POST",
              body: formData,
              headers: {
                Authorization: localStorage.getItem("tokenJwt"),
              },
            };

            try {
              const response = await fetch(
                `${API_URL}/api/upload`,
                requestOptions
              );
              const result = await response.json();
              return {
                url: `${API_URL}/${result?.url}`,
                fileid: result?.fileid,
              };
            } catch (error) {
              console.error(error);
              return { url: "" };
            }
          }}
        />
      </DemoBlock>
      <DemoBlock
        title={`Nội dung *`}
        desc={`Sử dụng Markdown để viết nội dung`}
      >
        <TextArea
          placeholder="Nội dung"
          value={m}
          onChange={(val) => {
            setM(val);
          }}
          maxLength={4096}
          showCount={true}
          autoSize={{ minRows: 8 }}
          style={{ border: "1px solid gray" }}
          className="p-[8px]"
        />
      </DemoBlock>

      {/* <DemoBlock title={`Button *`}>
        <Selector
          options={[
            {
              label: "Earn xAAO",
              value: "earn_xaao",
            },
            {
              label: "Play Game",
              value: "play_game",
            },
            {
              label: "Join Community",
              value: "join_community",
            },
            {
              label: "How to play",
              value: "how_play",
            },
          ]}
          defaultValue={buts}
          multiple
          onChange={(arr, extend) => {
            console.log(arr, extend.items);
            setButs(arr);
          }}
        />
      </DemoBlock> */}

      <DemoBlock title={`Buttons *`}>
        {/* <TextArea
          placeholder=""
          value={btns}
          onChange={(val) => {
            setBtns(val);
          }}
          autoSize={{ minRows: 8 }}
          style={{ border: "1px solid gray" }}
          className="p-[8px]"
        /> */}
        <SetButtons setBtns={setBtns} />
      </DemoBlock>

      <DemoBlock title={`Pick Users *`}>
        <PickUsers
          userType={userType}
          setUserType={setUserType}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
      </DemoBlock>

      <Divider />
      <div className="flex gap-[12px] mb-[100px]">
        <Button
          className="w-[50%]"
          color="primary"
          onClick={async () => {
            if (!m) {
              Toast.show({
                icon: "fail",
                content: "Vui lòng điền đủ thông tin",
              });
              return;
            }
            // send to api
            const body = {
              content: m,
              buttons: btns,
              fileid: (fileList[0] as any)?.fileid,
              chatId: telegramId,
              fromDate: fromDate.toISOString(),
              toDate: toDate.toISOString(),
              userType,
            };

            console.log("send_test", body);

            try {
              await axios.post(`${API_URL}/api/send_test`, body, {
                headers: {
                  Authorization: localStorage.getItem("tokenJwt"),
                },
              });
              Toast.show({ content: "Send test OK" });
            } catch (error: any) {
              Toast.show({
                icon: "fail",
                content: error?.response?.data?.message || "Error",
              });
            }
          }}
        >
          Send Test
        </Button>
        <Button
          className="w-[50%]"
          color="primary"
          onClick={() => {
            Modal.alert({
              content: "Bạn chắc chứ?",
              onConfirm: async () => {
                console.log("Confirmed");
                if (!m) {
                  Toast.show({
                    icon: "fail",
                    content: "Vui lòng điền đủ thông tin",
                  });
                  return;
                }
                // send to api
                const body = {
                  content: m,
                  buttons: btns,
                  fileid: (fileList[0] as any)?.fileid,
                  chatId: telegramId,
                  fromDate: fromDate.toISOString(),
                  toDate: toDate.toISOString(),
                  userType,
                };

                try {
                  await axios.post(`${API_URL}/api/send_confirm`, body, {
                    headers: {
                      Authorization: localStorage.getItem("tokenJwt"),
                    },
                  });
                } catch (error: any) {
                  Toast.show({
                    icon: "fail",
                    content: error?.response?.data?.message || "Error",
                  });
                }
              },
              confirmText: "Confirmed",
            });
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

const SetButtons = ({ setBtns }: any) => {
  const [btn, setBtn] = useState<any>({
    0: {
      id: "0",
      title: "🎁 Earn xAAO",
      value: "https://mission.aao.game",
      type: "web_app",
    },
    1: {
      id: "1",
      title: "⚔️ Play Game ⚔️",
      value: "https://aao.game/play/aao.php?v=2.0.0",
      type: "web_app",
    },
    2: {
      id: "2",
      title: "Join Community",
      value: "https://t.me/AAO_Game",
      type: "url",
    },
  });

  useEffect(() => {
    if (Object.values(btn).length > 0) {
      let str = Object.values(btn)
        .filter((m: any) => m?.type && m?.title && m?.value)
        .map((item: any) => `[${item?.type}][${item?.title}][${item?.value}]`)
        .join(",");
      console.log("str", str);
      if (str) {
        setBtns(str);
      }
    }
  }, [btn, setBtns]);

  return (
    <div>
      <div className="flex flex-col gap-[5px]">
        {Object.values(btn)?.map((item: any, index) => (
          <div key={item?.id} className="flex flex-row flex-wrap gap-[5px]">
            <select
              style={{ border: "1px solid gray" }}
              className=" flex-auto"
              name="type"
              onChange={(e) => {
                console.log(e.target.value);
                let cpBtn = { ...btn };
                cpBtn[item?.id] = { ...cpBtn[item?.id], type: e.target.value };
                setBtn(cpBtn);
              }}
              defaultValue={item?.type}
            >
              <option disabled value="">
                type
              </option>
              <option value="url">url</option>
              <option value="web_app">web_app</option>
              <option value="callback_data">callback_data</option>
            </select>
            <input
              defaultValue={item?.title}
              placeholder="title"
              style={{ border: "1px solid gray" }}
              name="title"
              className=" flex-auto p-[0_12px]"
              type="text"
              onChange={(e) => {
                let cpBtn = { ...btn };
                cpBtn[item?.id] = { ...cpBtn[item?.id], title: e.target.value };
                setBtn(cpBtn);
              }}
            />
            <input
              defaultValue={item?.value}
              placeholder="value"
              style={{ border: "1px solid gray" }}
              name="value"
              className=" flex-auto p-[0_12px]"
              type="text"
              onChange={(e) => {
                let cpBtn = { ...btn };
                cpBtn[item?.id] = { ...cpBtn[item?.id], value: e.target.value };
                setBtn(cpBtn);
              }}
            />
            <button
              style={{ border: "1px solid gray" }}
              className="w-[50px]"
              onClick={() => {
                let cpBtn = { ...btn };
                delete cpBtn[item?.id];
                setBtn(cpBtn);
              }}
            >
              x
            </button>
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          let cpBtn = { ...btn };
          let length = Date.now();
          cpBtn[length] = { id: length, type: "" };
          setBtn(cpBtn);
        }}
        style={{ border: "1px solid gray" }}
        className="w-[100%] mt-[12px]"
      >
        +
      </button>
    </div>
  );
};
