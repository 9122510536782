import { Radio, Space } from "antd-mobile";
import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { API_URL } from "./config";

const formatDateForInput = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

const PickUsers = ({
  userType,
  setUserType,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: any) => {
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    setFromDate(oneMonthAgo);
    setToDate(today);
  }, []);

  const handleDateChange =
    (setter: React.Dispatch<React.SetStateAction<Date>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(new Date(event.target.value));
    };

  useEffect(() => {
    const getCountUsers = async () => {
      if (userType === "active_in") {
        const res = await axios.get(`${API_URL}/api/active_in`, {
          params: { from: fromDate.toISOString(), to: toDate.toISOString() },
          headers: {
            Authorization: localStorage.getItem("tokenJwt"),
          },
        });
        setUserCount(Number(res?.data?.data || 0));
      }
    };
    getCountUsers().then().catch();
  }, [fromDate, toDate, userType]);

  return (
    <div>
      <Radio.Group
        value={userType}
        onChange={(value) => setUserType(value.toString() as any)}
      >
        <Space direction="horizontal">
          <Radio value="all">
            <span className="text-[13px]">All</span>
          </Radio>
          <Radio value="active_in">
            <span className="text-[13px]">Active In</span>
          </Radio>
        </Space>
      </Radio.Group>

      {userType === "active_in" && (
        <div className="flex flex-col mt-4">
          <div className="flex gap-3 mb-2">
            <span>From</span>
            <input
              type="date"
              value={formatDateForInput(fromDate)}
              onChange={handleDateChange(setFromDate)}
            />
          </div>
          <div className="flex gap-3 mb-4">
            <span>To</span>
            <input
              type="date"
              value={formatDateForInput(toDate)}
              onChange={handleDateChange(setToDate)}
            />
          </div>
          <p>User count: {userCount}</p>
        </div>
      )}
    </div>
  );
};

export default PickUsers;
