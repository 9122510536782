import { Divider, ProgressBar, Space, Tag } from "antd-mobile";
import axios from "axios";
import { useEffect, useState } from "react";
import { DemoBlock } from "./DemoBlock";
import { API_URL } from "./config";

export default function Queues() {
  const [data, setData] = useState([
    {
      id: 1,
    },
  ]);

  const fetchData = () => {
    axios
      .get(`${API_URL}/api/queues`, {
        headers: {
          Authorization: localStorage.getItem("tokenJwt"),
        },
      })
      .then((res: any) => {
        setData(res?.data?.data);
      });
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {data.map((item: any, i) => {
        let k = ((item?.sended + item?.error) * 100) / item?.total_user;
        k = Math.floor(k);
        let totalTime = Date.now() - new Date(item?.started_time).getTime();
        if (item?.status === "end") {
          totalTime =
            new Date(item?.ended_time).getTime() -
            new Date(item?.started_time).getTime();
        }
        if (totalTime < 1) {
          totalTime = 0;
        }

        return (
          <div key={i}>
            <DemoBlock title={`ID: ${item?.id}`}>
              <div>{item?.content}</div>
              <ProgressBar text={`${k}%`} percent={k} />
              <Space>
                <Tag color="primary">{item?.total_user} total</Tag>
                <Tag color="success">{item?.sended} Success</Tag>
                <Tag color="warning">{item?.error} Error</Tag>
                <Tag color="default">{item?.status}</Tag>
                <Tag color="default">{Math.ceil(totalTime / 1000)}s</Tag>
              </Space>
            </DemoBlock>
            <Divider />
          </div>
        );
      })}
    </div>
  );
}
